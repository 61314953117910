import React from 'react'
import Layout from '../components/Layout'
import Sitemap from '../components/shared/Sitemap/Sitemap'
import GeneralHeader from '../components/Header/GeneralHeader'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Sitemap',
    url: '/sitemap',
  },
]
const NotFoundPage = () => (
  <Layout activeLink="/sitemap" title="R&D Sitemap" description="R&D Sitemap">
    <GeneralHeader breadcrumbData={breadcrumbData} heading="Sitemap" />

    <Sitemap />
  </Layout>
)

export default NotFoundPage
