import React from 'react'
import styled from 'styled-components'
import VerticalPadding from '../../styling/VerticalPadding'
import SmallGrid from '../../styling/SmallGrid'
import theme from '../../styling/theme'

const col1 = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Tax-credit',
    url: '/tax-credit',
  },
  {
    title: 'Claims Process',
    url: '/claims-process',
  },
  {
    title: 'Sponsorship',
    url: '/sponsorships',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Contact us',
    url: '/contact-us',
  },
]

const col2 = [
  {
    title: 'Industry Sector',
    url: '/industry-sector',
    inside: [
      {
        title: 'Aerospace and Defence',
        url: '/aerospace-and-defence',
      },
      {
        title: 'Alarms/CCTV',
        url: '/alarms',
      },
      {
        title: 'Chemicals, Paints and Adhesives',
        url: '/chemicals-paints-adhesive',
      },
      {
        title: 'Testing',
        url: '/testing',
      },
      {
        title: 'Construction and Building Material',
        url: '/construction-and-building-material',
      },
      {
        title: 'Engineering And Machinery Design',
        url: '/engineering-and-machinery-design',
      },
      {
        title: 'Electronics and Electrical',
        url: '/electronics-and-electrical',
      },
      {
        title: 'Materials, Finishes and Construction',
        url: '/materials-finishes-and-construction',
      },
      {
        title: 'Sustainability',
        url: '/sustainability',
      },
      {
        title: 'Pharmaceuticals, Biotechnology and Medical Science',
        url: '/pharmaceuticals-biotechnology-and-medical-science',
      },
      {
        title: 'Projects',
        url: '/projects',
      },
    ],
  },
]

const col3 = [
  {
    title: 'Careers',
    url: '/careers',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
    inside: [
      {
        title: 'MLM Group',
        url: '/mlm-group',
      },
      {
        title: 'Beaumont Seymour',
        url: '/beaumont-seymour',
      },
      {
        title: 'Skyline Green Interiors',
        url: '/skyline-green-interiors',
      },
    ],
  },
  {
    title: 'Blog',
    url: 'http://blog.randdadvisors.co.uk/',
  },
  // {
  //   title: 'Terms',
  //   url: '/terms',
  // },
  {
    title: 'Privacy',
    url: '/privacy',
  },
]
const Sitemap = () => {
  return (
    <VerticalPadding>
      <SmallGrid>
        <Inner>
          <Container>
            <Col>
              {col1.map((link, i) => {
                return (
                  <div key={i}>
                    <a
                      href={
                        (process.env.BASE === '/' ? '' : process.env.BASE) +
                        link.url
                      }
                      key={i}
                    >
                      {link.title}
                    </a>
                    {link.inside &&
                      link.inside.map((item, key) => {
                        return (
                          <a
                            href={
                              (process.env.BASE === '/'
                                ? ''
                                : process.env.BASE) + item.url
                            }
                            key={key}
                            className="inside-link"
                          >
                            {item.title}
                          </a>
                        )
                      })}
                  </div>
                )
              })}
            </Col>
            <Col>
              {col2.map((link, i) => {
                return (
                  <div key={i}>
                    <a
                      href={
                        (process.env.BASE === '/' ? '' : process.env.BASE) +
                        link.url
                      }
                      key={i}
                    >
                      {link.title}
                    </a>
                    {link.inside &&
                      link.inside.map((item, key) => {
                        return (
                          <a
                            href={
                              (process.env.BASE === '/'
                                ? ''
                                : process.env.BASE) + item.url
                            }
                            key={key}
                            className="inside-link"
                          >
                            {item.title}
                          </a>
                        )
                      })}
                  </div>
                )
              })}
            </Col>
            <Col>
              {col3.map((link, i) => {
                return (
                  <div key={i}>
                    <a
                      href={
                        (process.env.BASE === '/' ? '' : process.env.BASE) +
                        link.url
                      }
                      key={i}
                    >
                      {link.title}
                    </a>
                    {link.inside &&
                      link.inside.map((item, key) => {
                        return (
                          <a
                            href={
                              (process.env.BASE === '/'
                                ? ''
                                : process.env.BASE) + item.url
                            }
                            key={key}
                            className="inside-link"
                          >
                            {item.title}
                          </a>
                        )
                      })}
                  </div>
                )
              })}
            </Col>
          </Container>
        </Inner>
      </SmallGrid>
    </VerticalPadding>
  )
}

const Inner = styled.div`
  width: 100%;
`

const HeadingContainer = styled.div`
  text-align: center;
  margin-bottom: 10rem;

  @media (max-width: 767px) {
    margin-bottom: 5rem;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & p {
    font-size: 2rem;
    line-height: 3rem;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  & div {
  }

  @media (max-width: 767px) {
    flex-direction: column;
    & div {
      margin-right: 0px;
    }
  }
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & a {
    display: block;
    color: ${theme.brand.blue};
    margin-bottom: 8px;
    &:hover {
      text-decoration: underline;
    }
  }
  & .link-parent {
    padding-bottom: 10px;
  }

  & .inside-link {
    padding-left: 15px;
    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 7.5px;
      background-color: ${theme.brand.blue};
      margin-right: 5px;
    }
  }
`
export default Sitemap
